import React from 'react';
import Editor from "grapesjs-react";
import grapesjs from 'grapesjs';
import siteBuilderPreset from './plugins/site-builder-preset';
import './App.scss';

function App() {

    return (
    <>
        <Editor
          id="builder"
          presetType="webpage"
          plugins={[siteBuilderPreset]}
      />
    </>


  );
}

export default App;
