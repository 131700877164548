export default (editor, config) => {
  const bm = editor.BlockManager;
  const toAdd = name => config.blocks.indexOf(name) >= 0;

  bm.add('about-block', {
    category: 'Rieltor`s blocks',
    label: '360 block',
    attributes: { class: 'fa fa-address-card-o' },
    content: `
        <div class="d-flex justify-content-between align-items-center"> 
          <div class="col-7">
            <div class="img-box">
              <img src="../img/tur.jpg" />
            </div>
          </div>
          <div class="col-4 pr-2">
            <h2 class="box-title">
                3D тур по квартире
            </h2>
            <p class="box-text">
                Технология 3D захвата реальности которая позволяла любому человеку быстро и качественно ознакомиться с любым объектом недвижимости и поделиться им с другими.
            </p>
            <button class="btn">ПОПРОБОВАТЬ</button>
          </div>
        </div>
        <style>
          .d-flex {
            display: flex;
          }
          .justify-content-between {
            justify-content: space-between;
          }
          .align-items-center {
            align-items: center;
          }
          
          .col-4 {
            width: 33.333333%;
          }
          .col-7 {
            width: 58.333333%;
          }
          
          .box-title {
            font-size: 54px;
          }
          .box-text {
            margin-bottom: 80px;
            font-size: 18px;
            line-height: 1.6; 
            letter-spacing: 0.055em;
          }
          
          .pl-2 {
            padding-left: 2rem;
          }
          
          .pr-2 {
            padding-right: 2rem;
          }
  
          .img-box {
            display: flex;
            justify-content: center;
          }
          .img-box img {
            display: block;
            width: 100%;
            object-fit: contain;
          }
          
          .btn {
            width: 250px;
            height: 80px;
            padding: 1rem 2rem;
            border: none;
            color: #fff;          
            background: #87AD60;
          }
        </style>
        `,
  });

  bm.add('ar-block', {
    category: 'Rieltor`s blocks',
    label: 'AR block',
    attributes: { class: 'fa fa-address-card-o' },
    content: `
        <div class="d-flex justify-content-between align-items-center"> 
          <div class="col-4 pl-2">
            <h2 class="box-title">
                Квартира в дополненной реальности
            </h2>
            <p class="box-text">
                Технология которая позволяет визуализировать 3D-модели в Расширенной Реальности, интегрированной в реальном времени, в натуральную величину и среду
            </p>
            <button class="btn">ПОПРОБОВАТЬ</button>
          </div>
          <div class="col-7">
            <div class="img-box">
              <img src="../img/tur.jpg" />
            </div>
          </div>
        </div>
        <style>
          .d-flex {
            display: flex;
          }
          .justify-content-between {
            justify-content: space-between;
          }
          .align-items-center {
            align-items: center;
          }
          
          .col-4 {
            width: 33.333333%;
          }
          .col-7 {
            width: 58.333333%;
          }
          
          .box-title {
            font-size: 54px;
          }
          .box-text {
            margin-bottom: 80px;
            font-size: 18px;
            line-height: 1.6; 
            letter-spacing: 0.055em;
          }
          
          .pl-2 {
            padding-left: 2rem;
          }
          
          .pr-2 {
            padding-right: 2rem;
          }
  
          .img-box {
            display: flex;
            justify-content: center;
          }
          .img-box img {
            display: block;
            width: 100%;
            object-fit: contain;
          }
          
          .btn {
            width: 250px;
            height: 80px;
            padding: 1rem 2rem;
            border: none;
            color: #fff;          
            background: #87AD60;
          }
        </style>
        `,
  });


  toAdd('link-block') && bm.add('link-block', {
    category: 'Basic',
    label: 'Link Block 1',
    attributes: { class: 'fa fa-link' },
    content: {
      type:'link',
      editable: false,
      droppable: true,
      style:{
        display: 'inline-block',
        padding: '5px',
        'min-height': '50px',
        'min-width': '50px'
      }
    },
  });

  toAdd('quote') && bm.add('quote', {
    label: 'Quote 1',
    category: 'Basic',
    attributes: { class: 'fa fa-quote-right' },
    content: `<blockquote class="quote">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit
      </blockquote>`
  });

  toAdd('text-basic') && bm.add('text-basic', {
    category: 'Basic',
    label: 'Text section 1',
    attributes: { class: 'gjs-fonts gjs-f-h1p' },
    content: `<section class="bdg-sect">
      <h1 class="heading">Insert title here</h1>
      <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
      </section>`
  });
}
